import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Slider from 'react-slick';

// Components
import Layout from '~components/layout'
import SEO from '~components/seo'
import TeamSlider from '~components/team-slider'

// CSS
import '~css/slick.css'

// Images
import vault from '~images/vault.svg'
import innovation from '~images/innovation.svg'
import goal from '~images/goal.svg'
import quotes from '~images/quotes.svg'
import img1 from '~images/img1.jpg'
import img4 from '~images/img4.jpg'
import iPhone from '~images/iphone-modern.png'
import tablet from '~images/tablet-modern.png'
import processPointer from '~images/process-pointer-1.svg'
import wave from '~images/wave-6-top-leftb.svg'
import mobileResponsive from '~images/mobile-responsive.svg'

const Home = () => {
  const img = useStaticQuery(graphql`
    query {
      bgimg: file(relativePath: { eq: "man_and_woman_working_on_tablet.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'text-right u-slick__pagination u-slick__pagination--white mt-2 mb-0',
    appendDots: dots => (
      <ul style={{ display: `flex` }}> {dots} </ul>
    ),
    customPaging: () => (
      <span></span>
    )
  }
  return (
    <Layout>
    <SEO title="QVR Home" description="Software Solution for I/DD Providers" /> 
      <main id="content" role="main">
        {/* Hero Section */}
        <div id="SVGheroBGShapes" className="position-relative gradient-half-qvr-v2 overflow-hidden">
          {/* Content */}
          <div className="container space-top-3 space-bottom-2 space-top-md-5 space-lg-4">
            <div className="row">
              <div className="col-md-6 position-relative z-index-2">
                <div className="mb-4">
                  <h1 className="display-4 text-white font-weight-normal">Finally! A software solution for I/DD service providers</h1>
                </div>
                <div className="mb-7">
                  <p className="lead text-white">We understand most of your time should be spent working with clients, not on documentation, billing, &amp; paperwork.</p>
                </div>

                {/* <Link className="js-fancybox media align-items-center u-media-player" to="/dead">
                  <span className="u-media-player__icon mr-3">
                    <span className="fas fa-play u-media-player__icon-inner"></span>
                  </span>
                  <span className="media-body">
                    Play video
                  </span>
                </Link> */}

              </div>
            </div>
          </div>
          {/* End Content */}

          {/* Mockup Devices v1 */}
          <div className="d-none d-md-flex align-items-end u-devices-v1">
            {/* SVG Phone Mockup */}
            <div className="u-devices-v1__phone">
              <div className="u-devices-v1__phone-svg">
                <figure className="ie-devices-v1-phone">
                  <img className="img-fluid" src={iPhone} aria-hidden="true" alt="" />
                </figure>
              </div>
            </div>
            {/* End SVG Phone Mockup */}

            {/* SVG Tablet Mockup */}
            <div className="u-devices-v1__tablet">
              <div className="u-devices-v1__tablet-svg">
                <figure className="ie-devices-v1-tablet">
                  <img className="img-fluid" src={tablet} aria-hidden="true" alt="" />
                </figure>
              </div>
            </div>
            {/* End SVG Tablet Mockup */}
          </div>
          {/* End Mockup Devices v1 */}

          {/* SVG Shapes */}
          <div className="w-25 position-absolute top-0 left-0">
            <figure className="ie-wave-6-top-left">
              <img className="img-fluid" src={wave} aria-hidden="true" alt="" />
            </figure>
          </div>
          {/* End SVG Shapes */}
        </div>
        {/* End Hero Section */}


        {/* Icon Blocks Section */}
        <div className="container space-2 space-md-3">
          {/* Title */}
          <div className="w-md-80 w-lg-70 text-center mx-md-auto mb-9">
            <h2 className="font-weight-normal">Our Mission</h2>
            <p className="mb-0">Enable our customers to focus on enhancing the lives of their clients by providing software that is easy to use, reduces or eliminates common errors, automates repetitive tasks, &amp; improves staff preformance.</p>
          </div>
          {/* End Title */}

          <div className="row justify-content-center mx-gutters-2">
            <div className="col-sm-6 col-lg-4 mb-7 mb-lg-0">
              {/* Icon Blocks */}
              <div className="text-center px-5">
                {/* SVG Icon */}
                <figure id="SVGmobileMarketing" className="w-75 mx-auto mb-4">
                  <img className="img-fluid" src={vault} alt="Security Vault" />
                </figure>
                {/* End SVG Icon */}

                {/* Info */}
                <div className="mb-4">
                  <h3 className="h5">Safe &amp; Secure</h3>
                  {/* TODO: Write this better */}
                  <p className="mb-md-0">QVR Software is HIPAA compliant, so you can rest assured your clients information is safe &amp; secure.</p>
                </div>
                {/* End Info */}

                <Link className="btn btn-sm btn-soft-primary btn-pill transition-3d-hover" to="/demo">
                  Get QVR
                  <span className="fas fa-angle-right ml-2"></span>
                </Link>
              </div>
              {/* End Icon Blocks */}
            </div>

            <div className="col-sm-6 col-lg-4 mb-7 mb-lg-0">
              {/* Icon Blocks */}
              <div className="text-center px-5">
                {/* SVG Icon */}
                <figure id="SVGservices" className="w-75 mx-auto mb-4">
                  <img className="img-fluid" src={innovation} alt="Inovation" />
                </figure>
                {/* End SVG Icon */}

                {/* Info */}
                <div className="mb-4">
                  <h3 className="h5">Complete Set of Tools</h3>
                  <p className="mb-md-0">We have included everything you need in one easy to use app.</p>
                </div>
                {/* End Info */}

                <Link className="btn btn-sm btn-soft-primary btn-pill transition-3d-hover" to="/demo">
                  See All That QVR Can Do
                  <span className="fas fa-angle-right ml-2"></span>
                </Link>
              </div>
              {/* End Icon Blocks */}
            </div>

            <div className="col-sm-6 col-lg-4">
              {/* Icon Blocks */}
              <div className="text-center px-5">
                {/* SVG Icon */}
                <figure id="SVGgraphicDesign" className="w-75 mx-auto mb-4">
                  <img className="img-fluid" src={goal} alt="brain in a box" />
                </figure>
                {/* End SVG Icon */}

                {/* Info */}
                <div className="mb-4">
                  <h3 className="h5">Goal Driven</h3>
                  <p className="mb-md-0">Software designed with your goals in mind, so you can focus on what&#39;s important. Your clients.</p>
                </div>
                {/* End Info */}

                <Link className="btn btn-sm btn-soft-primary btn-pill transition-3d-hover" to="/demo">
                  Hire QVR
                  <span className="fas fa-angle-right ml-2"></span>
                </Link>
              </div>
              {/* End Icon Blocks */}
            </div>
          </div>
        </div>
        {/* End Icon Blocks Section */}

        {/* Testimonials Section */}
        <BackgroundImage Tag="div"
          className="bg-img-hero-bottom"
          fluid={img.bgimg.childImageSharp.fluid}
          style={{ overflow: 'visible' }}
        >

          <div className="container">
            <div className="row justify-content-md-end">
              <div className="col-md-6 col-lg-5 col-xl-4 space-top-3 mb-n9">
                {/* Testimonials */}
                <div className="card border-0 bg-primary text-white shadow-primary-lg">
                  <div className="card-body p-7">
                    <Slider {...settings} className="js-slick-carousel u-slick">
                      {/* Testimonials */}
                      <div className="js-slide">
                        {/* SVG Quote */}
                        <figure className="mb-4">
                          <img src={quotes} style={{ width: '40px' }} aria-hidden="true" alt="" />
                        </figure>
                        {/* End SVG Quote */}

                        {/* Text */}
                        <blockquote className="h6 font-weight-normal text-lh-md mb-4">QVR has given time back to our agency. By bringing all of our documentation into one tight knit package, we are able to cut costs and maintain clean and compliant employee files, client files and keep in touch with our staff through the HRM. The EMAR product, really is a game changer.</blockquote>
                        {/* End Text */}

                        {/* Author */}
                        {/* <div className="media">
                          <div className="u-avatar mr-3">
                            <img className="img-fluid rounded-circle" src={img1} aria-hidden="true" alt="" />
                          </div>
                          <div className="media-body">
                            <h4 className="h6 mb-0">Maria Muszynska</h4>
                            <p className="small text-white-70">Google</p>
                          </div>
                        </div> */}
                        {/* End Author */}
                      </div>
                      {/* End Testimonials */}

                      {/* Testimonials */}
                      <div className="js-slide">
                        {/* SVG Quote */}
                        <figure className="mb-4">
                          <img src={quotes} style={{ width: '40px' }} aria-hidden="true" alt="" />
                        </figure>
                        {/* End SVG Quote */}

                        {/* Text */}
                        <blockquote className="h6 font-weight-normal text-lh-md mb-4">If you can design one thing you can design everything with QVR. Just believe it.</blockquote>
                        {/* End Text */}

                        {/* Author */}
                        {/* <div className="media">
                          <div className="u-avatar mr-3">
                            <img className="img-fluid rounded-circle" src={img4} aria-hidden="true" alt="" />
                          </div>
                          <div className="media-body">
                            <h4 className="h6 mb-0">Alex Pottorf</h4>
                            <p className="small text-white-70">Github</p>
                          </div>
                        </div> */}
                        {/* End Author */}
                      </div>
                      {/* End Testimonials */}
                    </Slider>
                  </div>
                </div>
                {/* End Testimonials */}
              </div>
            </div>
          </div>
        </BackgroundImage>
        {/* End Testimonials Section */}

        {/* Team Section */}
        <div className="container space-top-3 space-bottom-2 space-bottom-md-3">
          {/* Title */}
          <div className="w-md-80 w-lg-70 text-center mx-md-auto mb-9">
            <Link to="/about"><span className="btn btn-xs btn-soft-primary btn-pill mb-2">About Us</span></ Link>
            <h2 className="font-weight-normal">Executive Team</h2>
            <p>We have been where you are. Each of us has years of experience working in and/or owning an I/DD service provider. We were brought together by the understanding that this industry desperatly needed a different type of software. An easy to use tool which could help staff &amp; owners better understand &amp; serve the needs of their clients, and put the emphasis back on the client, instead of on paperwork &amp; documentation.</p>
          </div>
          {/* End Title */}

          {/* Team Carousel */}
          <TeamSlider />
          {/* End Team Carousel */}

          {/* Hire Us */}
          <div className="text-center">
            <div className="d-sm-inline-flex align-items-center shadow-sm rounded-pill p-2 pr-3">
              <Link to="/contact"><span className="btn btn-xs btn-soft-primary btn-pill mb-2 mb-sm-0 mr-2">New</span></ Link>
              <span className="d-block d-sm-inline-block">
                We are hiring.
                <Link to="/contact"> Send your application.</Link>
              </span>
            </div>
          </div>
          {/* End Hire Us */}
        </div>
        {/* End Team Section */}

        {/* Devices v2 Section */}
        <div id="SVGdevicesV2" className="gradient-half-qvr-v3 u-devices-v2">
          <div className="container space-2 space-md-3 position-relative z-index-2">
            {/* Title */}
            <div className="w-md-80 w-lg-50 text-center mx-md-auto">
              <span className="btn btn-lg btn-icon btn-white rounded-circle mb-4">
                {/* <span className="fas fa-hdd text-primary btn-icon__inner"></span> */}
                <img className="img-fluid" src={mobileResponsive} aria-hidden="true" alt="" />
              </span>
              <h2 className="h1 text-white">Device <span className="font-weight-semi-bold">friendly</span> software</h2>
              <p className="lead text-white-70 mb-0">QVR software is web based and fully responsive so your staff can use it on any device.</p>
            </div>
            {/* End Title */}
          </div>

          {/* Devices v2 */}
          <div className="d-none d-lg-block">
            {/* SVG Tablet Mockup */}
            <div className="u-devices-v2__tablet">
              <div className="w-75 u-devices-v2__tablet-svg">
                <figure className="ie-devices-v2-tablet">
                  <img className="img-fluid" src={tablet} aria-hidden="true" alt="" />
                </figure>
              </div>
            </div>
            {/* End SVG Tablet Mockup */}

            {/* SVG Phone Mockup */}
            <div className="u-devices-v2__phone">
              <div className="w-75 u-devices-v2__phone-svg">
                <figure className="ie-devices-v2-iphone">
                  <img className="img-fluid" src={iPhone} aria-hidden="true" alt="" />
                </figure>
              </div>
            </div>
            {/* End SVG Phone Mockup */}
          </div>
          {/* End Devices v2 */}

          {/* SVG Background Shape */}
          <figure className="w-100 content-centered-y position-absolute right-0 bottom-0">
            <img className="img-fluid" src={processPointer} aria-hidden="true" alt="" />
          </figure>
          {/* End SVG Background Shape */}
        </div>
        {/* End Devices v2 Section */}


      </main>
    </Layout>
  )
}
export default Home
