import React from 'react'
import Slider from 'react-slick'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// CSS
import '~css/slick.css'

const TeamSlider = () => {

  const img = useStaticQuery(graphql`
    query {
      devon: file(relativePath: { eq: "devon.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 492) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      cole: file(relativePath: { eq: "cole.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 492) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      jared: file(relativePath: { eq: "jared.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 492) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `)

  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 554,
        settings: {
          slidesToShow: 1
        }
      }
    ],
    dotsClass: 'd-lg-none text-center u-slick__pagination mb-7',
    appendDots: dots => (
      <ul style={{ display: `flex` }}> {dots} </ul>
    ),
    customPaging: () => (
      <span></span>
    )
  }
  return (
    <Slider {...settings} className="js-slick-carousel u-slick mb-5" style={{ display: `flex` }}>
      {/* Devon */}
      <div className="js-slide pb-4 px-3">
        <Img className="img-fluid w-100 rounded" fluid={img.devon.childImageSharp.fluid} alt="Devon Millington" />
        <div className="card border-0 shadow-sm mt-n6 mx-3">
          <div className="card-body text-center p-4">
            <h3 className="h6 mb-1">Devon Millington</h3>
            <p className="font-size-1 mb-0">CEO, Behavior Analyst</p>
          </div>
        </div>
      </div>
      {/* End Devon */}

      {/* Cole */}
      <div className="js-slide pb-4 px-3">
        <Img className="img-fluid w-100 rounded" fluid={img.cole.childImageSharp.fluid} alt="Cole Morley" />
        <div className="card border-0 shadow-sm mt-n6 mx-3">
          <div className="card-body text-center p-4">
            <h3 className="h6 mb-1">Cole Morley</h3>
            <p className="font-size-1 mb-0">CXO</p>
          </div>
        </div>
      </div>
      {/* End Cole */}

      {/* Jared */}
      <div className="js-slide pb-4 px-3">
        <Img className="img-fluid w-100 rounded" fluid={img.jared.childImageSharp.fluid} alt="Jared Whipple" />
        <div className="card border-0 shadow-sm mt-n6 mx-3">
          <div className="card-body text-center p-4">
            <h3 className="h6 mb-1">Jared Whipple</h3>
            <p className="font-size-1 mb-0">CTO</p>
          </div>
        </div>
      </div>
      {/* End Jared */}
    </Slider>

  )
}

export default TeamSlider
